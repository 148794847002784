<template>
  <div>
    <nav class="navbar top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <div class="navbar-item">
          <h1 class="title mb-0">
            Settings
          </h1>
        </div>
      </div>
      
      <div class="tabs is-hidden-tablet">
        <ul>
          <li :class="$route.name === 'Account' && 'is-active'">
            <router-link :to="{ name: 'Account' }">
              Account
            </router-link>
          </li>
          <li :class="$route.name === 'Payments' && 'is-active'">
            <router-link :to="{ name: 'Payments' }">
              Payments
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <router-view />

  </div>
</template>

<script>
</script>